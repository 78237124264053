<template>
  <div>
    <breadcrumbs :title="data.title"/>

    <div class="section section-border">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <h3>{{ data.title }}</h3>
            <img :src="data.image" @error="$event.target.src='https://via.placeholder.com/800x600'" alt=""
                 class="img-responsive img-rounded image-fit">
            <br>
            <div v-html="data.content"/>
          </div>
        </div>
        <div class="spacer-50"></div>

        <div class="row">
          <div class="col-sm-12 col-md-12" v-if="accordions.length > 0">
            <h3 v-if="accordions[0].title != null">Sub Program</h3>
            <div aria-multiselectable="true" class="panel-group panel-faq" id="accordion" role="tablist">
              <div :key="index" class="panel panel-default" v-for="(item, index) in accordions">
                <div class="panel-heading" id="heading1" role="tab" v-if="item.title != null">
                  <h4 class="panel-title">
                    <a :aria-controls="`collapse-${index}`" :href="`#collapse-${index}`" aria-expanded="true"
                       data-parent="#accordion"
                       data-toggle="collapse" role="button">
                      {{ item.meta.title }}
                    </a>
                  </h4>
                </div>
                <div :aria-labelledby="`heading-${index}`" :id="`collapse-${index}`" aria-expanded="true"
                     class="panel-collapse collapse"
                     role="tabpanel">
                  <div class="panel-body">
                    <div v-html="item.content"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Detail",
  components: {
    breadcrumbs: () => import('@/components/layout/Breadcrumb'),
  },
  data() {
    return {
      data: {
        title: null,
        content: null,
        image: null
      },
      accordions: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.data = {
        title: null,
        content: null,
        image: null
      };
      this.accordions = [];
      this.isLoading = true;
      try {
        var data = await this.$axios.get('pages/' + this.$route.name);
        const response = data.data.data;
        this.data = {
          title: response['title'],
          content: response['content'],
          image: response['featured_image_path']
        };
      } catch (e) {
        console.log(e);
      }
      this.isLoading = true;
      Promise.all([
        await this.requestSubProgram(1),
        await this.requestSubProgram(2),
        await this.requestSubProgram(3)]).then((values) => {
        this.accordions = values;
        this.isLoading = false;
      });
    },

    async requestSubProgram(number) {
      var data = {
        title: null
      }
      try {
        const tempData = await this.$axios.get('pages/' + this.$route.name + "-subprogram-" + number);
        data = tempData.data.data;
        return data;
      } catch (e) {
        console.log(e);
        return data;
      }
    }
  },
  watch: {
    '$route': function () {
      this.init();
    }
  }
}
</script>

<style scoped>

</style>
